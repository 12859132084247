import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { css } from "@emotion/core"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container" css={header}>
      <Link
        to="/"
      >
        <span role="img" aria-label="camera emoji">🎥</span> LIVE-VIDEO.CH
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header


const header = css`
  padding-top: 20px;
  padding-bottom: 20px;

  a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 700;
    background-color: white;
    padding: 10px 20px;
    border-radius: 50px;

    span {
      margin-right: 10px;
    }
  }
`
